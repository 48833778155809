<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-carousel cycle :height="$vuetify.breakpoint.name == 'xs'
          ? '100vh'
          : $vuetify.breakpoint.name == 'sm'
            ? '100vh'
            : $vuetify.breakpoint.name == 'md'
              ? '100vh'
              : $vuetify.breakpoint.name == 'lg'
                ? '100vh'
                : '100vh'
          " hide-delimiters :show-arrows="false" :interval="slideInterval">
          <v-carousel-item v-for="(item, i) in list" :key="i">
            <v-card flat>
              <v-img :lazy-src="mediaURL + item.image" :src="mediaURL + item.image" :alt="mediaURL + item.image"
                eager><template v-slot:placeholder>
                  <ImageLoader />
                </template>
                <v-layout wrap justify-end py-2>
                  <v-flex xs12 sm12 md11>
                    <v-layout wrap justify-end>
                      <v-flex xs12 sm6 md4 lg4 xl3 pa-5 align-self-center>
                        <v-card color="#005f32" flat tile height="93vh" style="opacity: 0.8;">
                          <v-layout wrap justify-center>
                            <v-flex xs12 sm6 md6 text-center align-self-center pt-5>
                              <v-img src="./../../assets/wtilogo.png" height="150px" width="200px" contain>
                              </v-img>
                            </v-flex>
                            <v-flex xs12 pt-4 pb-16>
                              <span style="
                                    color: #e27826;
                                    font-family: opensansbold;
                                    font-size: 20px;
                                  ">In service of nature</span>
                            </v-flex>
                            <v-flex xs12 pt-16>
                              <span style="
                                    color:white;
                                    font-family: opensansextrabold;
                                    font-size: 50px;
                                  ">
                                <number ref="number1" :from="0" :to="item.count" :duration="5" :delay="1"
                                  easing="Power1.easeOut" />
                              </span>
                            </v-flex>

                            <v-flex xs12 pt-10 pa-5 pb-16>
                              <span style="
                                    color: white;
                                    font-family: opensansbold;
                                    font-size: 28px;
                                  ">{{ item.tittle }}</span>
                            </v-flex>
                          </v-layout>
                        </v-card>

                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      slideInterval: 10000,
      list: [],
      items: [
        {
          count: "0",
          targetcount: "48154",
          title: "Animal lives saved",
          img: require("./../../assets/pic1.jpg"),
        },
        {
          count: "0",
          targetcount: "25681431",
          title: "Hectares of natural habitats secured",
          img: require("./../../assets/pic2.jpg"),
        },
        {
          count: "0",
          targetcount: "3",
          title: "Threatened species recovered",
          img: require("./../../assets/pic3.jpg"),
        },
        {
          count: "0",
          targetcount: "2103",
          title: "Elephants saved from train collisions",
          img: require("./../../assets/pic4.jpg"),
        },
        {
          count: "0",
          targetcount: "247 ",
          title:
            "Enforcement operations to dismantle wildlife trade & trafficking",
          img: require("./../../assets/pic5.jpg"),
        },
        {
          count: "0",
          targetcount: "18941",
          title: "Forest guards trained as part of the Van Rakshak Project",
          img: require("./../../assets/pic6.jpg"),
        },
        {
          count: "0",
          targetcount: "19444",
          title:
            "Families enabled with green livelihoods, reducing dependence on wildlife",
          img: require("./../../assets/pic7.jpg"),
        },
        {
          count: "0",
          targetcount: "2445341",
          title: "Children made aware of conservation",
          img: require("./../../assets/pic8.jpg"),
        },
      ],
      count: 0,
      totalTime: 0,
    };
  },
  beforeMount() {
    this.getCall();
    this.getData()
  },
  methods: {
    getCall() {
      setTimeout(function () {
        location.reload();
      }, 100000);
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/conservation/cycle/list",
      })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          // this.totalTime=this.list.length*this.slideInterval
          // if( this.totalTime>)
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>