var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-carousel',{attrs:{"cycle":"","height":_vm.$vuetify.breakpoint.name == 'xs'
        ? '100vh'
        : _vm.$vuetify.breakpoint.name == 'sm'
          ? '100vh'
          : _vm.$vuetify.breakpoint.name == 'md'
            ? '100vh'
            : _vm.$vuetify.breakpoint.name == 'lg'
              ? '100vh'
              : '100vh',"hide-delimiters":"","show-arrows":false,"interval":_vm.slideInterval}},_vm._l((_vm.list),function(item,i){return _c('v-carousel-item',{key:i},[_c('v-card',{attrs:{"flat":""}},[_c('v-img',{attrs:{"lazy-src":_vm.mediaURL + item.image,"src":_vm.mediaURL + item.image,"alt":_vm.mediaURL + item.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)},[_c('v-layout',{attrs:{"wrap":"","justify-end":"","py-2":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md11":""}},[_c('v-layout',{attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","lg4":"","xl3":"","pa-5":"","align-self-center":""}},[_c('v-card',{staticStyle:{"opacity":"0.8"},attrs:{"color":"#005f32","flat":"","tile":"","height":"93vh"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":"","text-center":"","align-self-center":"","pt-5":""}},[_c('v-img',{attrs:{"src":require("./../../assets/wtilogo.png"),"height":"150px","width":"200px","contain":""}})],1),_c('v-flex',{attrs:{"xs12":"","pt-4":"","pb-16":""}},[_c('span',{staticStyle:{"color":"#e27826","font-family":"opensansbold","font-size":"20px"}},[_vm._v("In service of nature")])]),_c('v-flex',{attrs:{"xs12":"","pt-16":""}},[_c('span',{staticStyle:{"color":"white","font-family":"opensansextrabold","font-size":"50px"}},[_c('number',{ref:"number1",refInFor:true,attrs:{"from":0,"to":item.count,"duration":5,"delay":1,"easing":"Power1.easeOut"}})],1)]),_c('v-flex',{attrs:{"xs12":"","pt-10":"","pa-5":"","pb-16":""}},[_c('span',{staticStyle:{"color":"white","font-family":"opensansbold","font-size":"28px"}},[_vm._v(_vm._s(item.tittle))])])],1)],1)],1)],1)],1)],1)],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }